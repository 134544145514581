@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.Clients {
  /* background-color:red; */
  width: 70%;
  text-align: center; 
  margin-top: 3rem;
  margin-bottom: 5rem;
  /* height: 570px; */
}
.Clients h1{
 color:#e0b03c;
 font-family: 'Jost',sans-serif;
}
.Clients h4{
  color:#ceb884;
}
.slide img {
  width: 70%;
  height: auto;
  margin: auto;
}

.slide {
  transform: scale(0.5);
  transition: transform 300ms;
  opacity: 0.5;
  position: relative;
  z-index: 1;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;
  z-index: 1000;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}
 
.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}
a{
  color: black;
  text-decoration: none;
  font-size: 14px;
  
  display: flex !important;
  justify-content: center; 
  align-items: center;
  flex-direction: column;
}
a:active,a:hover{
  color:black;
}

a:hover{
  scale: 1.3;
}
.slick-list{
  height: 35vh;
}
.slick-track{
  display: flex;
  height: 100%;
}

@media screen and (max-width: 829px) {
  .Clients {
    margin-top: 1rem;
    width:90%
  } 
  .slide img{
  } 
  .slick-list{
    height: 20vh;
  }
  a{
    font-size: 10px;
  }
}
@media screen and (max-width: 450px) {
  .Clients {
    margin-top: 1rem;
  } 
  /* .Clients h1{
    font-size:x-large;
  } */
  .Clients h4{
    font-size:small;
  }
  .slide img{
  } 
  .slick-list{
    height: 15vh;
  }
  a{
    font-size: 8px;
  }
}