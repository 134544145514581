.leftbox{

    position:sticky;
    background-color:#e0b03c;
    width:20% !important;
    /* margin: 90px 0px 80px -150px ; */
    margin-top:90px !important ;
     margin-right:-150px !important;
     margin-left:60px !important;

}
.maintitle{
    color: #e0b03c;
    font-family:'Jost', 'Merriweather', serif;
    font-weight:bold;
    font-size: 24px;
     /* color:#e0b03c; */
}
.cards{
    position:sticky ;
    margin:40px !important;
    padding:30px !important;
     background-color:rgba(255, 255, 255, 0.85); 
    

}
.firstPhara{
margin: 0 100px 0 50px ;
min-width: 100%;
max-height: 250px;
}

@media screen and (max-width:400px) {
    .firstPhara{
        padding: 0% !important;
    }
}