@media only screen and (max-width: 920px) {
   #container{
   margin:0!Important;
   flex-direction: column;
}
   .icons{
      padding-left:0!Important;
      column-gap: 0rem!Important;
   }
   .mainContainer{
      padding: 25px 5px;
}
.icon {
   margin: 3px!Important;
  }
   p { 
      font-size: 0.9em;
   }
   .link{
   }
}
@media only screen and (max-width: 640px) {
   #container{
   margin:0!Important;
}
   .icons{
      padding-left:0!Important;
      column-gap: 0rem!Important;
   }
   .mainContainer{
}
.icon {
   margin: 3px!Important;
  }
   p { 
      font-size: 0.8em;
      font-family: 'Jost', serif !important ;

   }
   .link{
      /* padding-top: 3rem; */
      max-width: fit-content;
   }
}
@media only screen and (max-width: 470px) {
   #container{
   margin:0!Important;
}
   .icons{
      padding-left:0!Important;
      column-gap: 0rem!Important;
   }
   .mainContainer{
}
.icon {
   margin: 3px!Important; 
  }
   p { 
      font-size: 0.7em;
      font-family: 'Jost', serif !important ;

   } 
   .link{
      /* padding-top: 3rem; */
      max-width: fit-content;
   }
}
@media only screen and (max-width: 380px) {
   #container{
   margin:0!Important;
}
   .icons{
      padding-left:0!Important;
      column-gap: 0rem!Important;
     
   }
   .mainContainer{
}
.icon {
   margin: 3px!Important;
  }
   p { 
      font-size: 0.6em;
    font-family: 'Jost', serif !important ;

   }
   .link{
      /* padding-top: 3rem; */
      max-width: fit-content;
   }
}
.mainContainer{
   display:flex;
   justify-content: space-between;
   background-color: #e0b03c;
   padding: 25px 25px;
}
#container{
   margin-top: 1rem;
   padding-left: 20px;
   display: flex;
   flex-direction: row;
}
p{
font-family: 'Jost', serif !important ;
}
h4{
font-family: 'Jost', 'Inter', serif !important ;

}
.icons {
   display: inline-flex;
   column-gap: 1rem;
 }
 
 .icon {
    margin: 10px;
   }
   .App-link {
      display: inline-block !important;
      color: rgb(41, 41, 14);
      font-weight:600;
      margin-left: 5px;
    }
   
 .icon:first-child {
   margin-left: 0;
 }
 
 .icon:last-child {
   margin-right: 0;
 }

 .mapouter{position:relative;text-align:right;height:100%;width:100vw;}
 .gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100vw;}


iframe{
   height:600px;
   width:100vw;
}
