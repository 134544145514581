
.sevicetitle{
text-align: center;
}
.sevicetitle h1{
      color:#e0b03c;
      font-family: 'Jost', sans-serif;
      
}
.sevicetitle h4{
       color:#ceb884;
}
.cardcontainer{
display:flex ;
justify-content:center;
width: 90%;
}
.boxcard{
    width: 100%;
     height:80%;
     padding:30px ;
     flex-grow: 1;
}
.cardcontents{
    border-left: 6px solid #e0b03c;
    height: 80px;
    padding-left:10px;
    
}
.mainbox{
    display:  block ;
    max-width:  80% ;
     max-height: 80% ;
     height: 80% ;
     flex-grow: 1 ;
      padding: 30px  !important;
}
.mainheader{
    text-align: center;
    padding-top: 10px;
}
.boximg{
          height: 300px;
          display: block;
          max-width:  100% ;
          max-height: 300px;
          overflow: hidden;
           width: 100%;
}
.verticalline{
    border-left: 6px solid #e0b03c;
    
    padding-left: 10px ;
}
 .card{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
 -webkit-line-clamp:2;
 -webkit-box-orient:vertical;

}
.label{
font-size:28px !important;
font-family: 'Jost','Inter', serif !important;
font-weight: 500;
}
.description{
font-family: 'Jost', serif !important ;
font-size: 20px !important;
}
.lessbutton{
    background-color:#e0b03c ;
    border: none;
    color: black ;
    width: 100px;
     padding: 5px 5px;
     text-align: center;
     margin-top: 5% ;
     margin-left: 90%;
     text-decoration: none;
     font-size: 13px;
      cursor: pointer;
}

.morebutton{ 
    background-color:#ffd164 ;
    border: none;
    color: black;
    padding: 5px 5px;
    text-align: center;
    margin-bottom: 2%;
    margin-left: 60%;
    text-decoration: none;
    font-size: 13px;
    cursor: pointer;
    box-shadow:0 12px 13px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

@media screen and (max-width:820px) {
    .label{
        font-size: 20px !important;
    }
    .description{
        font-size: 18px !important;
    }
    .mainbox{
        display:  block ;
        max-width:  100% ;
         max-height: 80% ;
         height: 80% ;
         flex-grow: 1 ;
          padding: 0 120px 0 120px !important;
    }
    .boximg{
        height: 280px;
        max-height: 300px;
  }
    .cardcontainer{
        display:flex ;
        justify-content:center;
        width: 90%;
        }
}
@media screen and (max-width:450px) {
    .label{
        font-size: 15px !important;
    }
    .description{
        font-size: 12px !important;
    }
    .mainbox{
        display:  block ;
        max-width:  100% ;
         max-height: 80% ;
         height: 80% ;
         flex-grow: 1 ;
          padding: 0px  !important;
    }
    .cardcontainer{
        display:flex ;
        justify-content:center;
        width: 90%;
        }

    .boximg{
        height: 180px;
        max-width:  100% ;
        max-height: 200px;
        overflow: hidden;
        width: 100%;
  }
  .lessbutton{
    background-color:#e0b03c ;
    border: none;
    color: black ;
    width: 100px;
     padding: 5px 5px;
     text-align: center;
     margin-top: 5% ;
     margin-left: 70%;
     text-decoration: none;
     font-size: 13px;
      cursor: pointer;
}
}
